import Image from 'next/image';
import { oneLine } from 'common-tags';

import { Container, NextLink, Text } from '@/atoms';

import { removeBaseURL } from '@/lib/utils';
import Button from '../button';

import type { FunctionComponent } from 'react';
import type { TypeCardStepProps } from './types';

/**
 * CardStep
 */
export const CardStep: FunctionComponent<TypeCardStepProps> = ({
  className = '',
  pretitle,
  title,
  subtitle,
  description,
  button,
  image,
  size = 'large',
  stroke,
  headingType = 'p',
}: TypeCardStepProps) => (
  <Container
    justify="justify-start"
    align="items-center"
    textAlign="text-center"
    className={oneLine` ${size === 'small' ? 'h-[420px] w-72 gap-6 !px-6 !py-10' : ''} ${size === 'large' ? 'w-[385px] gap-8 !py-10 px-[60px]' : ''} ${stroke ? 'border border-primary-200' : ''} ${className} `}
  >
    {image && (
      <div className="max-w-[140px]">
        <Image
          src={image.sourceUrl}
          width={image.mediaDetails?.width}
          height={image.mediaDetails?.height}
          alt={image.altText}
        />
      </div>
    )}

    <div
      className={oneLine`flex flex-col items-center gap-4 ${size === 'large' ? 'w-[266px]' : ''} ${size === 'small' ? 'w-60' : ''} `}
    >
      <div className="flex flex-col gap-1">
        {pretitle && (
          <span className="u-label u-label--s font-bold uppercase text-primary-600">
            {pretitle}
          </span>
        )}

        {title && (
          <Text
            as={headingType}
            className="u-subtitle u-subtitle--l text-primary-900"
          >
            {title}
          </Text>
        )}

        {subtitle && (
          <span className="u-subtitle u-subtitle--s text-secondary-500">
            {subtitle}
          </span>
        )}
      </div>

      {description && (
        <div
          className="u-body u-body--s font-medium [&_b]:font-normal [&_b]:text-secondary-500 [&_strong]:font-normal [&_strong]:text-secondary-500"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      )}

      {button && button.link && (
        <NextLink href={removeBaseURL(button?.link?.url ?? '')} passHref>
          <Button negative={button.negativo} as="a" variant={button.variant}>
            {button.label}
          </Button>
        </NextLink>
      )}
    </div>
  </Container>
);
